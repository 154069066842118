import { ConfigProvider } from "antd";
import Stats from "./stats";


function App() {
  return (
    <div className="App">
      <ConfigProvider>
      <Stats/>
      </ConfigProvider>
    </div>
  );
}

// <iframe
//   src="https://loc-stats.netlify.app/" 
//   width="100%" 
//   height="1000px" 
//   frameborder="0" 
//   style="border:0; overflow:hidden;"
//   sandbox="allow-scripts allow-same-origin">
// </iframe>

export default App;
