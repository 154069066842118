import React, { useCallback, useState } from "react";
import dayjs from "dayjs";
import PlayerPlaceHolderImg from "./player_placeholer.png";
import Utils from "../utils";
import { Grid } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import "./AlternateTeamsNameLogo.css"; // Import custom CSS for animations

const AlternateTeamsNameLogo = React.memo(
  ({ record, teamsInfo, settings, setmatchstats, setopenmatchstats }) => {
    const [showSecondChild, setShowSecondChild] = useState(false);

    const handleContainerClick = () => {
      setopenmatchstats(true);
      setmatchstats(record);
    };
    const toggleChild = () => {
      setShowSecondChild((prev) => !prev);
    };

    const { useBreakpoint } = Grid;
    const screens = Utils.getBreakPoint(useBreakpoint());
    const isMobile = screens.length === 0 ? false : !screens.includes("lg");

    const filterTeamInfo = useCallback(
      (teamId) => teamsInfo.find((x) => x?._id === teamId),
      [teamsInfo]
    );

    const filterlogohome = filterTeamInfo(record?.home?.team?._id)?.logo;
    const filternamehome = filterTeamInfo(record?.home?.team?._id)?.name;
    const filterscorehome = record?.home?.score;
    const homeTotalWins = record?.home?.wins;
    const homeTotalLoss = record?.home?.losses;

    const filterlogoaway = filterTeamInfo(record?.away?.team?._id)?.logo;
    const filternameaway = filterTeamInfo(record?.away?.team?._id)?.name;
    const filterscoreaway = record?.away?.score;
    const awayTotalWins = record?.away?.wins;
    const awayTotalLoss = record?.away?.losses;

    const getTopPlayer = useCallback((players, stat) => {
      return players
        .sort((a, b) => b.stats[stat] - a.stats[stat])
        .slice(0, 1)[0];
    }, []);

    const homePlayers = record?.home?.players || [];
    const awayPlayers = record?.away?.players || [];

    const home_top_pts = getTopPlayer(homePlayers, "pts");
    const home_top_reb = getTopPlayer(homePlayers, "reb");
    const home_top_ast = getTopPlayer(homePlayers, "ast");

    const away_top_pts = getTopPlayer(awayPlayers, "pts");
    const away_top_reb = getTopPlayer(awayPlayers, "reb");
    const away_top_ast = getTopPlayer(awayPlayers, "ast");

    const getTeamColor = useCallback(
      (teamName) => {
        const winningTeam =
          parseInt(record?.home?.score ?? "0") >
          parseInt(record?.away?.score ?? "0")
            ? "home"
            : "away";
        return winningTeam === teamName ? "#e11647" : "#444";
      },
      [record?.home?.score, record?.away?.score]
    );

    const getTeamTier = useCallback(
      (teamTier) => {
        const tier = teamTier;
        return process.env.REACT_APP_DEV_API_REDIRECT_URL.includes("tcbl")
          ? tier
            ? tier === "N/A"
              ? `${(
                  settings?.divisions?.[record?.home?.tier] ?? tier
                ).toUpperCase()} & ${(
                  settings?.divisions?.[record?.away?.tier] ?? tier
                ).toUpperCase()}`
              : (settings?.divisions?.[tier] ?? tier).toUpperCase()
            : "-"
          : tier === "1"
          ? "East"
          : "West";
      },
      [settings?.divisions, record?.home?.tier, record?.away?.tier]
    );

    const status = record?.status;

    return (
      <>
        <div
          onClick={() => {
            handleContainerClick();
          }}
          style={{ padding: "20px 30px", position: "relative" }}
        >
          {/* Date, Status, and Tier Section */}
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              display: "flex",
              paddingTop: "20px",
            }}
          >
            <div>
              <span
                style={{
                  borderRadius: "2px 2px 0px 0px",
                  color: "#777",
                  fontSize: "16.8px",
                  fontWeight: "700",
                }}
              >
                {dayjs(new Date(record?.date)).format("MMM DD")}
              </span>
              <span>{` - `}</span>
              <span
                style={{ color: "#777", fontSize: "16.8px", fontWeight: "700" }}
              >
                {status === "Completed" && record?.publishstatus === "Published"
                  ? "Completed"
                  : "Pending"}
              </span>
              <span style={{ color: "#777", fontWeight: "700" }}>
                {/* {` (${getTeamTier()})`} */}
              </span>
            </div>
          </div>

          <div
            className={`slide-container ${
              showSecondChild ? "show-second" : ""
            }`}
            style={{
              display: isMobile ? "block" : "inline-flex",
              justifyContent:
                isMobile && showSecondChild ? "center" : "flex-start",
              alignItems: "center",
              border: "1px solid #c0c0c0",
              // position: "relative",
              flexDirection: "row",
              width:
                isMobile &&
                showSecondChild &&
                status === "Completed" &&
                record?.publishstatus === "Published"
                  ? "400px"
                  : "",
              marginRight: "10px",
            }}
          >
            {/* Child 1 */}
            <div
              className="child-div"
              style={{
                borderRight: isMobile ? "none" : "1px solid #c0c0c0",
                padding: "10px",
                width: isMobile && showSecondChild ? "0" : "315px",
                display: isMobile && showSecondChild ? "none" : "block",
                transition: "width 0.5s",
              }}
            >
              {/* Content of the first child */}
              {/* Add your existing content for child 1 here */}
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ padding: "5px" }}>
                    <img
                      src={
                        !!filterlogohome
                          ? filterlogohome
                          : process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                              "tcbl"
                            )
                          ? "https://tcbl.ca/logos/logo.png"
                          : "https://tcbl2.poindexters.ca/img/loc.jpg"
                      }
                      height="54px"
                      width="54px"
                      alt=""
                    />
                  </div>
                  <div style={{ padding: "5px" }}>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#444",
                        width: "100px",
                        wordWrap: "break-word",
                      }}
                    >
                      {filternamehome}
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#444",
                        paddingTop: "5px",
                      }}
                    >
                      {homeTotalWins}-{homeTotalLoss}
                      <span style={{ fontWeight: "400" }}>
                        {" "}
                        | {getTeamTier(record?.home?.tier)}
                      </span>
                    </div>
                  </div>
                  <div style={{ padding: "5px" }}>
                    <span
                      style={{
                        fontSize: "16px",
                        color: getTeamColor("home"),
                        fontWeight: "500",
                      }}
                    >
                      {filterscorehome}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ padding: "5px" }}>
                    <img
                      src={
                        !!filterlogoaway
                          ? filterlogoaway
                          : process.env.REACT_APP_DEV_API_REDIRECT_URL.includes(
                              "tcbl"
                            )
                          ? "https://tcbl.ca/logos/logo.png"
                          : "https://tcbl2.poindexters.ca/img/loc.jpg"
                      }
                      height="54px"
                      width="54px"
                      alt=""
                    />
                  </div>
                  <div style={{ padding: "5px" }}>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#444",
                        width: "100px",
                        wordWrap: "break-word",
                      }}
                    >
                      {filternameaway}
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#444",
                        paddingTop: "5px",
                      }}
                    >
                      {awayTotalWins}-{awayTotalLoss}
                      <span style={{ fontWeight: "400" }}>
                        {" "}
                        | {getTeamTier(record?.away?.tier)}
                      </span>
                    </div>
                  </div>
                  <div style={{ padding: "5px" }}>
                    <span
                      style={{
                        fontSize: "16px",
                        color: getTeamColor("away"),
                        fontWeight: "500",
                      }}
                    >
                      {filterscoreaway}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Child 2 */}
            <div
              className="child-div"
              style={{
                padding: "10px",
                display: isMobile && !showSecondChild ? "none" : "block",
                width: isMobile && !showSecondChild ? "0px" : "",
                transition: "width 0.5s",
              }}
            >
              {status === "Completed" &&
              record?.publishstatus === "Published" ? (
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: isMobile ? "380px" : "415px",
                  }}
                >
                  {/* home team */}
                  <div
                    style={{
                      // width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "flex-start",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                    >
                      <div style={{ padding: "5px", minWidth: "30px" }} />
                      <div
                        style={{
                          padding: "5px",
                          borderBottom: "1px solid #F0F0F0",
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          height: "40px",
                        }}
                      >
                        {`${filternamehome}`}
                      </div>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          padding: "5px",
                          minWidth: "30px",
                          fontSize: "12px",
                          color: "#777",
                        }}
                      >
                        PTS
                      </div>
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          borderBottom: "1px solid #F0F0F0",
                          width: "100%",
                        }}
                      >
                        {!isMobile && (
                          <div style={{ paddingRight: "5px" }}>
                            <img
                              src={
                                !!home_top_pts?.player.profile_pic
                                  ? home_top_pts?.player.profile_pic
                                  : PlayerPlaceHolderImg
                              }
                              height="30px"
                              width="30px"
                              style={{ borderRadius: "25px" }}
                              alt=""
                            />
                          </div>
                        )}
                        <div style={{ padding: "0px 5px" }}>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#000",
                              fontWeight: "400",
                              width: "80px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {home_top_pts?.player?.first_name}{" "}
                            {home_top_pts?.player?.last_name}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "400",
                            }}
                          >
                            #{home_top_pts?.jersey_no}
                          </div>
                        </div>
                        <div style={{ paddingLeft: "20px" }}>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "500",
                            }}
                          >
                            {home_top_pts?.stats?.pts}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          padding: "5px",
                          minWidth: "30px",
                          fontSize: "12px",
                          color: "#777",
                        }}
                      >
                        REB
                      </div>
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          borderBottom: "1px solid #F0F0F0",
                          width: "100%",
                        }}
                      >
                        {!isMobile && (
                          <div style={{ paddingRight: "5px" }}>
                            <img
                              src={
                                !!home_top_reb?.player.profile_pic
                                  ? home_top_reb?.player.profile_pic
                                  : PlayerPlaceHolderImg
                              }
                              height="30px"
                              width="30px"
                              style={{ borderRadius: "25px" }}
                              alt=""
                            />
                          </div>
                        )}
                        <div style={{ padding: "0px 5px" }}>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#000",
                              fontWeight: "400",
                              width: "80px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {home_top_reb?.player?.first_name}{" "}
                            {home_top_reb?.player?.last_name}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "400",
                            }}
                          >
                            #{home_top_reb?.jersey_no}
                          </div>
                        </div>
                        <div style={{ paddingLeft: "20px" }}>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "500",
                            }}
                          >
                            {home_top_reb?.stats?.reb}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          padding: "5px",
                          minWidth: "30px",
                          fontSize: "12px",
                          color: "#777",
                        }}
                      >
                        AST
                      </div>
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          borderBottom: "1px solid #F0F0F0",
                          width: "100%",
                        }}
                      >
                        {!isMobile && (
                          <div style={{ paddingRight: "5px" }}>
                            <img
                              src={
                                !!home_top_ast?.player.profile_pic
                                  ? home_top_ast?.player.profile_pic
                                  : PlayerPlaceHolderImg
                              }
                              height="30px"
                              width="30px"
                              style={{ borderRadius: "25px" }}
                              alt=""
                            />
                          </div>
                        )}
                        <div style={{ padding: "0px 5px" }}>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#000",
                              fontWeight: "400",
                              width: "80px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {home_top_ast?.player?.first_name}{" "}
                            {home_top_ast?.player?.last_name}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "400",
                            }}
                          >
                            #{home_top_ast?.jersey_no}
                          </div>
                        </div>
                        <div style={{ paddingLeft: "20px" }}>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "500",
                            }}
                          >
                            {home_top_ast?.stats?.ast}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* away team */}
                  <div
                    style={{
                      // width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      borderLeft: "1px solid #f0f0f0",
                      marginLeft: isMobile ? "0px" : "20px",
                      paddingLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "flex-start",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                    >
                      <div style={{ padding: "5px", minWidth: "30px" }} />
                      <div
                        style={{
                          padding: "5px",
                          borderBottom: "1px solid #F0F0F0",
                          width: "100%",
                          height: "40px",
                        }}
                      >
                        {`${filternameaway}`}
                      </div>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          borderBottom: "1px solid #F0F0F0",
                          width: "100%",
                        }}
                      >
                        {!isMobile && (
                          <div style={{ paddingRight: "5px" }}>
                            <img
                              src={
                                !!away_top_pts?.player.profile_pic
                                  ? away_top_pts?.player.profile_pic
                                  : PlayerPlaceHolderImg
                              }
                              height="30px"
                              width="30px"
                              style={{ borderRadius: "25px" }}
                              alt=""
                            />
                          </div>
                        )}
                        <div style={{ padding: "0px 5px" }}>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#000",
                              fontWeight: "400",
                              width: "80px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {away_top_pts?.player?.first_name}{" "}
                            {away_top_pts?.player?.last_name}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "400",
                            }}
                          >
                            #{away_top_pts?.jersey_no}
                          </div>
                        </div>
                        <div style={{ paddingLeft: "20px" }}>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "500",
                            }}
                          >
                            {away_top_pts?.stats?.pts}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          borderBottom: "1px solid #F0F0F0",
                          width: "100%",
                        }}
                      >
                        {!isMobile && (
                          <div style={{ paddingRight: "5px" }}>
                            <img
                              src={
                                !!away_top_reb?.player.profile_pic
                                  ? away_top_reb?.player.profile_pic
                                  : PlayerPlaceHolderImg
                              }
                              height="30px"
                              width="30px"
                              style={{ borderRadius: "25px" }}
                              alt=""
                            />
                          </div>
                        )}
                        <div style={{ padding: "0px 5px" }}>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#000",
                              fontWeight: "400",
                              width: "80px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {away_top_reb?.player?.first_name}{" "}
                            {away_top_reb?.player?.last_name}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "400",
                            }}
                          >
                            #{away_top_reb?.jersey_no}
                          </div>
                        </div>
                        <div style={{ paddingLeft: "20px" }}>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "500",
                            }}
                          >
                            {away_top_reb?.stats?.reb}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          borderBottom: "1px solid #F0F0F0",
                          width: "100%",
                        }}
                      >
                        {!isMobile && (
                          <div style={{ paddingRight: "5px" }}>
                            <img
                              src={
                                !!away_top_ast?.player.profile_pic
                                  ? away_top_ast?.player.profile_pic
                                  : PlayerPlaceHolderImg
                              }
                              height="30px"
                              width="30px"
                              style={{ borderRadius: "25px" }}
                              alt=""
                            />
                          </div>
                        )}
                        <div style={{ padding: "0px 5px" }}>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#000",
                              fontWeight: "400",
                              width: "80px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {away_top_ast?.player?.first_name}{" "}
                            {away_top_ast?.player?.last_name}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "400",
                            }}
                          >
                            #{away_top_ast?.jersey_no}
                          </div>
                        </div>
                        <div style={{ paddingLeft: "20px" }}>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "500",
                            }}
                          >
                            {away_top_ast?.stats?.ast}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: isMobile ? "" : "438px",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 20px",
                      border: "2px solid red",
                      // fontSize: "20px",
                      color: "red",
                      borderRadius: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    STATS ARE PENDING
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobile && (
          <div
            className="toggle-icon"
            onClick={toggleChild}
            style={{
              position: "absolute",
              top: "30px",
              right: "30px",
              transform: "translateY(-50%)",
              cursor: "pointer",
              background: "#fff",
              borderRadius: "10px",
              boxShadow: "0 0 5px rgba(0,0,0,0.3)",
              padding: "5px 10px",
              zIndex: 10,
              // width: "40px",
            }}
          >
            {showSecondChild ? (
              <DoubleLeftOutlined style={{ fontSize: "10px" }} />
            ) : (
              <DoubleRightOutlined style={{ fontSize: "10px" }} />
            )}
          </div>
        )}
      </>
    );
  }
);

export default AlternateTeamsNameLogo;
