/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Table, Grid, Tooltip, Switch, Button } from "antd";
//import { useEffect, useState } from "react";
// import dayjs from "dayjs";
// import axios from "axios";
// import { env } from "../../../../configs/EnvironmentConfig";
// import { General_Constants } from "constants/GeneralConstant";
//import log from "../../../../assets/images/log.png";
// https://leagueofchamps.ca/wp-includes/custom-components/stats/static/media/basketball-jersey.57047d5c4b0ac3f066f6.png
//import loc from "../../../../assets/images/loc.png";
import log from "../../assets/images/log.png";

import loc from "../../assets/images/loc.png";
import { useEffect, useState } from "react";
import { LeftCircleOutlined } from "@ant-design/icons";
import { env } from "../../configs/EnvironmentConfig";
import basktetball from "../../assets/images/basketball-jersey.png";
import axios from "axios";
import styles from "./stats.module.scss";
import rankicon from "../../assets/images/rank.svg";
import ApiConfig from "../../configs/ApiConfig";
import Utils from "../../utils";

const Stat = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { useBreakpoint } = Grid;
  const [teamstats, setteamstats] = useState([]);
  const [showPlayerstats, setshowPlayerstats] = useState(false);
  const [playerStats, setPlayerStats] = useState([]);
  console.log(teamstats, "teamstatsteamstats");
  const [settings, setsettings] = useState("");
  const getaccountsettings = async () => {
    try {
      const response = await axios.get(
        `${env?.API_ENDPOINT_URL}/${ApiConfig.website.getaccountsettings}`
      );
      setsettings(response?.data?.[0]?.logo);
      // form.setFieldsValue({homepage:response?.data?.[0]?.homepage,
      //   home:response?.data?.[0]?.hiddenmenu?.includes("/home"),
      //   rule:response?.data?.[0]?.hiddenmenu?.includes("/rule"),
      //   portal:response?.data?.[0]?.portal,
      //   rulelink:response?.data?.[0]?.rule
      // })
    } catch (error) {
      return [];
    }
  };
  useEffect(() => {
    getaccountsettings();
  }, []);

  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  const imgurl = process.env.REACT_APP_DEV_API_REDIRECT_URL.includes("tcbl")
    ? "https://tcblbasketball.s3.ca-central-1.amazonaws.com"
    : "https://leagueofchamps.s3.ca-central-1.amazonaws.com";
  const image = process.env.REACT_APP_DEV_API_REDIRECT_URL.includes("tcbl")
    ? log
    : loc;
  const getteamplayerstats = async (team) => {
    const leagueid = props.leaguesList?.find(
      (item) => item.name === props.league
    );
    const { data } = await axios.post(
      `${env?.API_ENDPOINT_URL}/settings/getTeamPlayerStats`,
      {
        tier: props.tier,
        season: props.season,
        league: leagueid.value,
        team: team,
      }
    );
    setPlayerStats([...data]);
  };
  const scoreColumns = [
    {
      title: (
        <div className={isMobile ? styles.data : styles.input}>
          <span className={styles.margin}>
            <img src={rankicon} alt="#" width="15px" />
          </span>{" "}
          Team
        </div>
      ),
      dataIndex: "name",
      fixed: "left",
      width: 150,
      key: "name",
      render: (record, stats, key) => (
        <div
          onClick={() => {
            getteamplayerstats(stats.team);
            setshowPlayerstats(true);
          }}
          className={isMobile ? styles.property : styles.module}
        >
          <span className={styles.rank}>{key + 1}</span>{" "}
          <Tooltip title={stats?.name}>
            <img
              src={Boolean(stats?.logo) ? imgurl + stats?.logo : settings}
              height="15px"
              width="15px"
              alt="img"
            />
          </Tooltip>{" "}
          {record}
        </div>
      ),
    },
    props.tier === "All"
      ? {
          title: <span className={styles.output}>Division</span>,
          dataIndex: "tier",
          width: 90,
          key: "tier",
          fixed: isMobile ? "" : "left",
          render: (record, stats) => (
            <div className={isMobile ? styles.font : undefined}>
              {(
                props.settings?.divisions?.[props.tier] ?? props.tier
              ).toUpperCase()}
            </div>
          ),
        }
      : { width: "0px" },
    {
      title: <span className={styles.mobile}>GP</span>,
      dataIndex: "gp",
      key: "gp",
      fixed: isMobile ? "" : "left",
      width: 40,
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>W</span>,
      dataIndex: "wons",
      key: "wons",
      width: 30,
      fixed: isMobile ? "" : "left",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>L</span>,
      dataIndex: "wons",
      key: "wons",
      width: 30,
      fixed: isMobile ? "" : "left",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {stats.gp - stats.wons}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>PTS</span>,
      dataIndex: "pts",
      key: "pts",
      width: 50,
      sorter: (a, b) => a.pts - b.pts,
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>FGM</span>,
      dataIndex: "fgm",
      key: "fgm",
      width: 50,
      sorter: (a, b) => a["fgm"] - b["fgm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fgm}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FGA</span>,
      dataIndex: "fga",
      key: "fga",
      width: 50,
      sorter: (a, b) => a["fga"] - b["fga"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fga}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FG%</span>,
      dataIndex: "fga",
      key: "fga",
      width: 50,
      sorter: (a, b) => a["fgm"] / a["fga"] - b["fgm"] / b["fga"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fga)
              ? parseFloat(record.fgm / record.fga).toFixed(1) * 100
              : parseFloat(0).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PM</span>,

      dataIndex: "2ptm",
      key: "2ptm",
      width: 50,
      sorter: (a, b) => a["2ptm"] - b["2ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2ptm"]) ? record["2ptm"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PA</span>,
      dataIndex: "2pta",
      width: 50,
      key: "2pta",
      sorter: (a, b) => a["2pta"] - b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"]) ? record["2pta"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2P%</span>,
      dataIndex: "2pta",
      width: 50,
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / a["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PM</span>,
      width: 50,
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] - b["3ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3ptm"]) ? record["3ptm"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PA</span>,
      dataIndex: "3pta",
      width: 50,
      key: "3pta",
      sorter: (a, b) => a["3pta"] - b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"]) ? record["3pta"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3P%</span>,
      dataIndex: "3pta",
      width: 50,
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / a["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTM</span>,
      dataIndex: "ftm",
      key: "ftm",
      width: 50,
      sorter: (a, b) => a.ftm - b.ftm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.ftm}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTA</span>,
      dataIndex: "fta",
      width: 50,
      key: "fta",
      sorter: (a, b) => a["fta"] - b["fta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fta}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FT%</span>,
      dataIndex: "fta",
      width: 50,
      key: "fta",
      sorter: (a, b) => a["ftm"] / a["fta"] - b["ftm"] / a["fta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fta)
              ? parseFloat((record.ftm / record.fta) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>OREB</span>,
      dataIndex: "oreb",
      key: "oreb",
      width: 60,
      sorter: (a, b) => a.oreb - b.oreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>DREB</span>,
      dataIndex: "dreb",
      key: "dreb",
      width: 60,
      sorter: (a, b) => a.dreb - b.dreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>REB</span>,
      dataIndex: "reb",
      key: "reb",
      width: 50,
      sorter: (a, b) => a.reb - b.reb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>AST</span>,
      dataIndex: "ast",
      key: "ast",
      width: 50,
      sorter: (a, b) => a.ast - b.ast,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>STL</span>,
      dataIndex: "stl",
      key: "stl",
      width: 50,
      sorter: (a, b) => a.stl - b.stl,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>BLK</span>,
      dataIndex: "blk",
      key: "blk",
      width: 50,
      sorter: (a, b) => a.blk - b.blk,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>TO</span>,
      dataIndex: "to",
      key: "to",
      width: 40,
      sorter: (a, b) => a.to - b.to,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>PF</span>,
      dataIndex: "pf",
      key: "pf",
      width: 40,
      sorter: (a, b) => a["pf"] - b["pf"],
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {record ?? 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TF</span>,
      dataIndex: "tf",
      key: "tf",
      width: 40,
      sorter: (a, b) => a["tf"] - b["tf"],
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {record ?? 0}
          </div>
        ),
    },
  ];
  const scoreColumnsAverage = [
    {
      title: (
        <div className={isMobile ? styles.data : styles.input}>
          <span className={styles.margin}>
            <img src={rankicon} alt="#" width="15px" />
          </span>{" "}
          Team
        </div>
      ),
      dataIndex: "name",
      fixed: "left",
      width: 150,
      key: "name",
      render: (record, stats, key) => (
        <div
          onClick={() => {
            getteamplayerstats(stats.team);
            setshowPlayerstats(true);
          }}
          className={isMobile ? styles.property : styles.module}
        >
          <span className={styles.output}>{key + 1}</span>{" "}
          <Tooltip title={stats?.name}>
            <img
              src={Boolean(stats?.logo) ? imgurl + stats?.logo : settings}
              height="15px"
              width="15px"
              alt="img"
            />
          </Tooltip>{" "}
          {record}
        </div>
      ),
    },
    props.tier === "All"
    ? {
        title: <span className={styles.mobile}>Division</span>,
        dataIndex: "tier",
        width: 90,
        key: "tier",
        fixed: isMobile ? "" : "left",
        render: (record, stats) => (
          <div className={isMobile ? styles.font : undefined}>
            {(
              props.settings?.divisions?.[props.tier] ?? props.tier
            ).toUpperCase()}
          </div>
        ),
      }
    : { width: "0px" },
    {
      title: <span className={styles.mobile}>GP</span>,
      dataIndex: "gp",
      fixed: isMobile ? "" : "left",
      key: "gp",
      width: 40,
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },

    {
      title: <span className={styles.mobile}>W</span>,
      dataIndex: "wons",
      key: "wons",
      width: 40,
      fixed: isMobile ? "" : "left",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>L</span>,
      dataIndex: "wons",
      key: "wons",
      width: 40,
      fixed: isMobile ? "" : "left",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {stats.gp - stats.wons}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>PTS</span>,
      dataIndex: "pts",
      key: "pts",
      width: 50,
      sorter: (a, b) => a.pts / a.gp - b.pts / a.gp,
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {parseFloat(record / stats.gp).toFixed(2)}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>FGM</span>,
      dataIndex: "fgm",
      width: 50,
      key: "fgm",
      sorter: (a, b) => a["fgm"] / a.gp - b["fgm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.fgm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FGA</span>,
      dataIndex: "fga",
      width: 50,
      key: "fga",
      sorter: (a, b) => a["fga"] / a.gp - b["fga"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.fga ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FG%</span>,
      dataIndex: "fga",
      width: 50,
      key: "fga",
      sorter: (a, b) => a["fgm"] / a.fga - b["fgm"] / a.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fga)
              ? parseFloat((record.fgm / record.fga) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PM</span>,
      width: 50,
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] / a.gp - b["2ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2ptm"])
              ? parseFloat(record["2ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PA</span>,
      dataIndex: "2pta",
      width: 50,
      key: "2pta",
      sorter: (a, b) => a["2pta"] / a.gp - b["2pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat(record["2pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2P%</span>,
      dataIndex: "2pta",
      width: 50,
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / a["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PM</span>,
      width: 50,
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] / a.gp - b["3ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3ptm"])
              ? parseFloat(record["3ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PA</span>,
      dataIndex: "3pta",
      width: 50,
      key: "3pta",
      sorter: (a, b) => a["3pta"] / a.gp - b["3pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat(record["3pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3P%</span>,
      dataIndex: "3pta",
      width: 50,
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / a["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTM</span>,
      dataIndex: "ftm",
      key: "ftm",
      width: 50,
      sorter: (a, b) => a.ftm / a.gp - b.ftm / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.ftm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTA</span>,
      dataIndex: "fta",
      width: 50,
      key: "fta",
      sorter: (a, b) => a["fta"] / a.gp - b["fta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.fta ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FT%</span>,
      dataIndex: "fta",
      width: 50,
      key: "fta",
      sorter: (a, b) => a["ftm"] / a["fta"] - b["ftm"] / a["fta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fta)
              ? parseFloat((record.ftm / record.fta) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>OREB</span>,
      dataIndex: "oreb",
      key: "oreb",
      width: 60,
      sorter: (a, b) => a.oreb / a.gp - b.oreb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>DREB</span>,
      dataIndex: "dreb",
      key: "dreb",
      width: 60,
      sorter: (a, b) => a.dreb / a.gp - b.dreb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>REB</span>,
      dataIndex: "reb",
      key: "reb",
      width: 50,
      sorter: (a, b) => a.reb / a.gp - b.reb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>AST</span>,
      dataIndex: "ast",
      key: "ast",
      width: 50,
      sorter: (a, b) => a.ast / a.gp - b.ast / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>STL</span>,
      dataIndex: "stl",
      key: "stl",
      width: 50,
      sorter: (a, b) => a.stl / a.gp - b.stl / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>BLK</span>,
      dataIndex: "blk",
      key: "blk",
      width: 50,
      sorter: (a, b) => a.blk / a.gp - b.blk / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TO</span>,
      dataIndex: "to",
      key: "to",
      width: 40,
      sorter: (a, b) => a.to / a.gp - b.to / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>PF</span>,
      dataIndex: "pf",
      key: "pf",
      width: 40,
      sorter: (a, b) => a["pf"] / a.gp - b["pf"] / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TF</span>,
      dataIndex: "tf",
      key: "tf",
      width: 40,
      sorter: (a, b) => a["tf"] / a.gp - b["tf"] / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
  ];

  const scoreColumnsPlayer = [
    {
      title: (
        <div className={isMobile ? styles.stage : styles.dataindex}>Player</div>
      ),
      dataIndex: "name",
      fixed: "left",
      width: 150,
      key: "name",
      render: (record, stats) => (
        <div
          onClick={() => getteamplayerstats(stats.team)}
          className={isMobile ? styles.property : styles.module}
        >
          {stats.first_name} {stats.last_name}
        </div>
      ),
    },
    {
      title: (
        <span>
          <img src={basktetball} alt="#" width="15px" />
        </span>
      ),
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? 70 : 50,
      render: (record, stats) => (
        <div>
          <span className={styles.stagemob}>
            <span className={styles.point}>#</span>
            {stats?.jersey_no}
          </span>{" "}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>GP</span>,
      dataIndex: "gp",
      key: "gp",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>W</span>,
      dataIndex: "w",
      key: "w",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>L</span>,
      dataIndex: "l",
      key: "l",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {stats?.gp - (stats?.w ?? 0)}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>PTS</span>,
      dataIndex: "pts",
      key: "pts",
      sorter: (a, b) => a.pts - b.pts,
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>FGM</span>,
      dataIndex: "fgm",
      width: 50,
      key: "fgm",
      sorter: (a, b) => a.fgm - b.fgm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fgm}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FGA</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a.fga - b.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fga}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FG%</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a?.fgm / a?.fga - b?.fgm / b?.fga,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fga)
              ? parseFloat((record.fgm / record.fga) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PM</span>,
      width: "70px",
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] - b["2ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2ptm"]) ? record["2ptm"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PA</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2pta"] - b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"]) ? record["2pta"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2P%</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / a["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PM</span>,
      width: "70px",
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] - b["3ptm"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3ptm"]) ? record["3ptm"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PA</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3pta"] - b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"]) ? record["3pta"] : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3P%</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTM</span>,
      dataIndex: "ftm",
      key: "ftm",
      width: "70px",
      sorter: (a, b) => a.ftm - b.ftm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.ftm}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTA</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a.fta - b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record.fta}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>FT%</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a.ftm / b.fta - b.ftm / b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fta)
              ? parseFloat((record.ftm / record.fta) * 100).toFixed(3)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>OREB</span>,
      dataIndex: "oreb",
      key: "oreb",
      width: "70px",
      sorter: (a, b) => a.oreb - b.oreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>DREB</span>,
      dataIndex: "dreb",
      key: "dreb",
      width: "70px",
      sorter: (a, b) => a.dreb - b.dreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>REB</span>,
      dataIndex: "reb",
      key: "reb",
      sorter: (a, b) => a.reb - b.reb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>AST</span>,
      dataIndex: "ast",
      key: "ast",
      sorter: (a, b) => a.ast - b.ast,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>STL</span>,
      dataIndex: "stl",
      key: "stl",
      sorter: (a, b) => a.stl - b.stl,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>BLK</span>,
      dataIndex: "blk",
      key: "blk",
      sorter: (a, b) => a.blk - b.blk,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>TO</span>,
      dataIndex: "to",
      key: "to",
      sorter: (a, b) => a.to - b.to,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>{record}</div>
        ),
    },
    {
      title: <span className={styles.mobile}>PF</span>,
      dataIndex: "pf",
      key: "pf",
      sorter: (a, b) => a.pf - b.pf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {record ?? 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TF</span>,
      dataIndex: "tf",
      key: "tf",
      sorter: (a, b) => a.tf - b.tf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {record ?? 0}
          </div>
        ),
    },
  ];
  const scoreColumnsPlayerAverage = [
    {
      title: (
        <div className={isMobile ? styles.stage : styles.dataindex}>Player</div>
      ),
      dataIndex: "name",
      fixed: "left",
      width: 150,
      key: "name",
      render: (record, stats) => (
        <div
          onClick={() => getteamplayerstats(stats.team)}
          className={isMobile ? styles.property : styles.module}
        >
          {stats.first_name} {stats.first_name}
        </div>
      ),
    },
    {
      title: (
        <span>
          <img src={basktetball} alt="#" width="15px" />
        </span>
      ),
      dataIndex: "gp",
      key: "gp",
      width: isMobile ? 70 : 50,
      render: (record, stats) => (
        <div>
          <span className={styles.stagemob}>
            <span className={styles.point}>#</span>
            {stats?.jersey_no}
          </span>{" "}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>GP</span>,
      dataIndex: "gp",
      key: "gp",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>W</span>,
      dataIndex: "w",
      key: "w",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>{record}</div>
      ),
    },
    {
      title: <span className={styles.mobile}>L</span>,
      dataIndex: "l",
      key: "l",
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {stats?.gp - (stats?.w ?? 0)}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>PTS</span>,
      dataIndex: "pts",
      key: "pts",
      sorter: (a, b) => a.pts / a.gp - b.pts / a.gp,
      render: (record, stats) => (
        <div className={isMobile ? styles.font : undefined}>
          {parseFloat(record / stats.gp).toFixed(2)}
        </div>
      ),
    },
    {
      title: <span className={styles.mobile}>FGM</span>,
      dataIndex: "fgm",
      width: 50,
      key: "fgm",
      sorter: (a, b) => a.fgm / a.gp - b.fgm / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.fgm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FGA</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      sorter: (a, b) => a.fga / a.gp - b.fga / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.fga ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FG%</span>,
      dataIndex: "fga",
      width: "70px",
      key: "fga",
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fga)
              ? parseFloat((record.fgm / record.fga) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PM</span>,
      width: "70px",
      dataIndex: "2ptm",
      key: "2ptm",
      sorter: (a, b) => a["2ptm"] / a.gp - b["2ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2ptm"])
              ? parseFloat(record["2ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2PA</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2pta"] / a.gp - b["2pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat(record["2pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>2P%</span>,
      dataIndex: "2pta",
      width: "70px",
      key: "2pta",
      sorter: (a, b) => a["2ptm"] / a["2pta"] - b["2ptm"] / b["2pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["2pta"])
              ? parseFloat((record["2ptm"] / record["2pta"]) * 100).toFixed(2)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PM</span>,
      width: "70px",
      dataIndex: "3ptm",
      key: "3ptm",
      sorter: (a, b) => a["3ptm"] / a.gp - b["3ptm"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3ptm"])
              ? parseFloat(record["3ptm"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3PA</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3pta"] / a.gp - b["3pta"] / a.gp,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat(record["3pta"] / record.gp).toFixed(2)
              : 0}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>3P%</span>,
      dataIndex: "3pta",
      width: "70px",
      key: "3pta",
      sorter: (a, b) => a["3ptm"] / a["3pta"] - b["3ptm"] / b["3pta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record["3pta"])
              ? parseFloat((record["3ptm"] / record["3pta"]) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTM</span>,
      dataIndex: "ftm",
      key: "ftm",
      width: "70px",
      sorter: (a, b) => a.ftm - b.ftm,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.ftm ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FTA</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a.fta - b.fta,
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record?.fta ?? 0) / record.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>FT%</span>,
      dataIndex: "fta",
      width: "70px",
      key: "fta",
      sorter: (a, b) => a["ftm"] / a["fta"] - b["ftm"] / b["fta"],
      render: (text, record) =>
        Boolean(record?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {Boolean(record.fta)
              ? parseFloat((record.ftm / record.fta) * 100).toFixed(1)
              : parseFloat(0).toFixed(3)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>OREB</span>,
      dataIndex: "oreb",
      key: "oreb",
      width: "70px",
      sorter: (a, b) => a.oreb / a.gp - b.oreb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>DREB</span>,
      dataIndex: "dreb",
      key: "dreb",
      width: "70px",
      sorter: (a, b) => a.dreb - b.dreb,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>REB</span>,
      dataIndex: "reb",
      key: "reb",
      sorter: (a, b) => a.reb / a.gp - b.reb / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>AST</span>,
      dataIndex: "ast",
      key: "ast",
      sorter: (a, b) => a.ast / a.gp - b.ast / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>STL</span>,
      dataIndex: "stl",
      key: "stl",
      sorter: (a, b) => a.stl / a.gp - b.stl / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>BLK</span>,
      dataIndex: "blk",
      key: "blk",
      sorter: (a, b) => a.blk / a.gp - b.blk / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TO</span>,
      dataIndex: "to",
      key: "to",
      sorter: (a, b) => a.to / a.gp - b.to / a.gp,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>PF</span>,
      dataIndex: "pf",
      key: "pf",
      sorter: (a, b) => a.pf - b.pf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
    {
      title: <span className={styles.mobile}>TF</span>,
      dataIndex: "tf",
      key: "tf",
      sorter: (a, b) => a.tf - b.tf,
      render: (record, stats) =>
        Boolean(stats?.dnp) ? (
          <></>
        ) : (
          <div className={isMobile ? styles.font : undefined}>
            {parseFloat((record ?? 0) / stats.gp).toFixed(2)}
          </div>
        ),
    },
  ];
  // const scoreColumnsBasicPlayer = [
  //   {
  //     title: <div  style={isMobile
  //       ? { color: "#000", fontSize: "13px", fontWeight: "700",textAlign:'left',paddingLeft:'15px'}
  //       : { color: "#000000", fontSize: "14px", fontWeight: "700",textAlign:'left',paddingLeft:'15px' }}>Player</div>,
  //     dataIndex: "name",
  //     fixed: "left",
  //     width: isMobile ? 100 : 150,
  //     key: "name",
  //     render: (record, stats) => (
  //       <div
  //       style={isMobile?{textAlign: "left", fontWeight: "300", color: "#000",fontSize:"10px",whiteSpace: "nowrap",
  //       overflow: "hidden",textOverflow: "ellipsis",paddingLeft:'20px'}:{ textAlign: "left", fontWeight: "500", color: "#000000",paddingLeft:'20px',fontSize:isMobile?"12px":undefined,whiteSpace: "nowrap",
  //       overflow: "hidden",textOverflow: "ellipsis" }}
  //       >
  //         {stats.first_name} {stats.first_name}
  //       </div>
  //     ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>GP</span>,
  //     dataIndex: "gp",
  //     key: "gp",
  //     render: (record, stats) => (
  //       <div style={isMobile ? { fontSize: "12px" } : undefined}>{record}</div>
  //     ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>PTS</span>,
  //     dataIndex: "pts",
  //     key: "pts",
  //     sorter: (a, b) => a.pts - b.pts,
  //     render: (record, stats) => (
  //       <div style={isMobile ? { fontSize: "12px" } : undefined}>{record}</div>
  //     ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>FGM</span>,
  //     dataIndex: "fgm",
  //     width: "70px",
  //     key: "fgm",
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record.fgm}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>FGA</span>,
  //     dataIndex: "fga",
  //     width: "70px",
  //     key: "fga",
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record.fga}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>FG%</span>,
  //     dataIndex: "fga",
  //     width: "70px",
  //     key: "fga",
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {Boolean(record.fga)
  //             ? parseFloat(record.fgm / record.fga).toFixed(3)
  //             : parseFloat(0).toFixed(3)}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>2PM</span>,
  //     width: "70px",
  //     dataIndex: "2ptm",
  //     key: "2ptm",
  //     sorter: (a, b) => a["2ptm"] - b["2ptm"],
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {Boolean(record["2ptm"]) ? record["2ptm"] : 0}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>2PA</span>,
  //     dataIndex: "2pta",
  //     width: "70px",
  //     key: "2pta",
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {Boolean(record["2pta"]) ? record["2pta"] : 0}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>2P%</span>,
  //     dataIndex: "2pta",
  //     width: "70px",
  //     key: "2pta",
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {Boolean(record["2pta"])
  //             ? parseFloat(record["2ptm"] / record["2pta"]).toFixed(3)
  //             : parseFloat(0).toFixed(3)}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>3PM</span>,
  //     width: "70px",
  //     dataIndex: "3ptm",
  //     key: "3ptm",
  //     sorter: (a, b) => a["3ptm"] - b["3ptm"],
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {Boolean(record["3ptm"]) ? record["3ptm"] : 0}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>3PA</span>,
  //     dataIndex: "3pta",
  //     width: "70px",
  //     key: "3pta",
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {Boolean(record["3pta"]) ? record["3pta"] : 0}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>3P%</span>,
  //     dataIndex: "3pta",
  //     width: "70px",
  //     key: "3pta",
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {Boolean(record["3pta"])
  //             ? parseFloat(record["3ptm"] / record["3pta"]).toFixed(3)
  //             : parseFloat(0).toFixed(3)}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>FTM</span>,
  //     dataIndex: "ftm",
  //     key: "ftm",
  //     width: "70px",
  //     sorter: (a, b) => a.ftm - b.ftm,
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record.ftm}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>FTA</span>,
  //     dataIndex: "fta",
  //     width: "70px",
  //     key: "fta",
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record.fta}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>FT%</span>,
  //     dataIndex: "fta",
  //     width: "70px",
  //     key: "fta",
  //     render: (text, record) =>
  //       Boolean(record?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {Boolean(record.fta)
  //             ? parseFloat(record.ftm / record.fta).toFixed(3)
  //             : parseFloat(0).toFixed(3)}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>OREB</span>,
  //     dataIndex: "oreb",
  //     key: "oreb",
  //     width: "70px",
  //     sorter: (a, b) => a.oreb - b.oreb,
  //     render: (record, stats) =>
  //       Boolean(stats?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>DREB</span>,
  //     dataIndex: "dreb",
  //     key: "dreb",
  //     width: "70px",
  //     sorter: (a, b) => a.dreb - b.dreb,
  //     render: (record, stats) =>
  //       Boolean(stats?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>REB</span>,
  //     dataIndex: "reb",
  //     key: "reb",
  //     sorter: (a, b) => a.reb - b.reb,
  //     render: (record, stats) =>
  //       Boolean(stats?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>AST</span>,
  //     dataIndex: "ast",
  //     key: "ast",
  //     sorter: (a, b) => a.ast - b.ast,
  //     render: (record, stats) =>
  //       Boolean(stats?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>STL</span>,
  //     dataIndex: "stl",
  //     key: "stl",
  //     sorter: (a, b) => a.stl - b.stl,
  //     render: (record, stats) =>
  //       Boolean(stats?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>BLK</span>,
  //     dataIndex: "blk",
  //     key: "blk",
  //     sorter: (a, b) => a.blk - b.blk,
  //     render: (record, stats) =>
  //       Boolean(stats?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>TO</span>,
  //     dataIndex: "to",
  //     key: "to",
  //     sorter: (a, b) => a.to - b.to,
  //     render: (record, stats) =>
  //       Boolean(stats?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>PF</span>,
  //     dataIndex: "pf",
  //     key: "pf",
  //     render: (record, stats) =>
  //       Boolean(stats?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record ?? 0}
  //         </div>
  //       ),
  //   },
  //   {
  //     title: <span style={{ fontWeight: "700", color: "#000" }}>TF</span>,
  //     dataIndex: "tf",
  //     key: "tf",
  //     render: (record, stats) =>
  //       Boolean(stats?.dnp) ? (
  //         <></>
  //       ) : (
  //         <div style={isMobile ? { fontSize: "12px" } : undefined}>
  //           {record ?? 0}
  //         </div>
  //       ),
  //   },
  // ];
  const getteamstats = async () => {
    const leagueid = props.leaguesList?.find(
      (item) => item.name === props.league
    );
    const { data } = await axios.post(
      `${env?.API_ENDPOINT_URL}/settings/getTeamStats`,
      { tier: props.tier, season: props.season, league: leagueid.value }
    );
    if (props.tier === "All") {
      setteamstats([...data]);
    } else {
      const v1 = data.filter((item) =>
        item?.registered_teams.includes(item?.team)
      );
      setteamstats([...v1]);
    }
  };
  useEffect(() => {
    getteamstats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.season, props.league, props.tier]);

  // eslint-disable-next-line no-unused-vars

  return (
    <>
      <div className={styles.container}>
        {showPlayerstats && (
          <div className={isMobile ? undefined : styles.stats}>
            <Button type="link" onClick={() => setshowPlayerstats(false)}>
              <LeftCircleOutlined size="large" />
              Back
            </Button>
          </div>
        )}
        {showPlayerstats ? (
          <Table
            columns={
              props.detailType === "1"
                ? scoreColumnsPlayer
                : scoreColumnsPlayerAverage
            }
            dataSource={playerStats?.map((item, index) => {
              return { ...item, rank: index };
            })}
            scroll={{
              x: isMobile ? 800 : 1200,
              y: 400,
            }}
            pagination={false}
          />
        ) : (
          <Table
            columns={
              props.detailType === "1" ? scoreColumns : scoreColumnsAverage
            }
            dataSource={teamstats?.map((item, index) => {
              return { ...item, rank: index };
            })}
            scroll={{
              x: isMobile ? 800 : 1200,
              y: 400,
            }}
            pagination={false}
          />
        )}
      </div>
    </>
  );
};
export default Stat;
