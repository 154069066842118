export const General_Constants = {
  TCBL: 'TCBL',
  LOC: 'LOC',
  STANDINGS: 'Standings',
  STATS: 'Stats',
  SCORES:'Scores',
  SCHEDULE:'Schedule',
  PLAYOFFS:'Playoffs',
  TEAM:'Team',
  GP:'GP',
  W:'W',
  L:'L',
  PCT:'PCT',
  PF:'PF',
  PA:'PA',
  DIFF:'Diff',
  GAME:'Game',
  DATE:'Date',
  TIER:'Tier',
  STATUS:'Status',
  TIME:'Time',
  HOME:'Home',
  AWAY:'Away',
  SEASON:'Season',
  LEADERS:'Leaders',
  PLAYERS:'Players',
  TEAMSTATS:'Teams',
};
